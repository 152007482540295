/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.6.0/dist/jquery.min.js
 * - /npm/timeago@1.6.7/jquery.timeago.min.js
 * - /npm/jquery-lazy@1.7.11/jquery.lazy.min.js
 * - /npm/jquery-lazy@1.7.11/jquery.lazy.plugins.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
